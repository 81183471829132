import { ComponentType, Context, createContext } from 'react'

import { ErrorBanner, Spinner } from 'ds/components'
import { OnboardingLayout } from 'lib/common/OnboardingLayout'
import { Layout } from 'lib/common/Layout'
import { ImpersonationBanner } from 'lib/common/ImpersonationBanner'

import { useSession } from 'lib/hooks/useSession'
import { hasPermission } from '../utils/hasPermission'
import { InitMetadata, Metadata, useMetadata } from '../dataLoaders/useMetadata'
import { Role } from 'lib/models/role'
import { Session } from 'lib/models/session'
import { useWarnExpiredOrgs } from 'lib/hooks/useWarnExpiringOrgs'
import { AccountLanguage } from 'lib/models/account'
import { Currency } from 'lib/models/currency'

export type Options = {
    isSuperAdminPage?: boolean
    permission?: string
    onboardingLayout?: boolean
}

const InitSession: Session = {
    role: Role.READ_ONLY,
    company: 'Default',
    permissions: [],
    account_id: 0,
    user_id: 0,
    email: '',
    syncfy_id_user: null,
    use_stp: 0,
    has_authorized_sat_credential: 0,
    has_financial_account: 0,
    has_invoice: 0,
    is_portal_enabled: false,
    is_ecart_pay_enabled: false,
    is_ecart_pay_activated: false,
    expiring_orgs: [],
    expires_at: new Date(),
    cfdi_disabled: false,
}

type SessionContext = {
    session: Session
    metadata: Metadata
    language: AccountLanguage
    primaryCurrency: Currency
    cfdiDisabled: boolean
}

const InitContext: SessionContext = {
    session: InitSession,
    metadata: InitMetadata,
    language: 'es',
    primaryCurrency: Currency.MXN,
    cfdiDisabled: false,
}

export const SessionContext: Context<SessionContext> = createContext(InitContext)

export const withSession = (
    WrappedComponent: ComponentType<{ session?: Session }>,
    { isSuperAdminPage, permission, onboardingLayout }: Options = {}
) => {
    return function WithSession() {
        const { session, isLoading: sessionIsLoading, error: sessionError } = useSession()
        const {
            metadata,
            isLoading: metadataIsLoading,
            error: metadataError,
        } = useMetadata(!!session)

        useWarnExpiredOrgs({ session })

        const LayoutWrapper = onboardingLayout ? OnboardingLayout : Layout

        // If session errors it will redirect to login
        if (metadataIsLoading || sessionIsLoading || sessionError) {
            return (
                <LayoutWrapper isLoading>
                    <Spinner />
                </LayoutWrapper>
            )
        }

        if (metadataError) {
            return (
                <LayoutWrapper>
                    <ErrorBanner>{metadataError}</ErrorBanner>
                </LayoutWrapper>
            )
        }

        const isSuperadmin = session?.role === 'superadmin'

        if (
            !session ||
            (isSuperAdminPage && !isSuperadmin) ||
            (permission && !hasPermission(session, permission))
        ) {
            return <LayoutWrapper>{null}</LayoutWrapper>
        }

        const language = metadata.account.language
        const primaryCurrency = metadata.account.primary_currency
        const cfdiDisabled = !!session.cfdi_disabled

        return (
            <SessionContext.Provider
                value={{ session, metadata, language, primaryCurrency, cfdiDisabled }}
            >
                <LayoutWrapper>
                    {isSuperadmin ? <ImpersonationBanner session={session} /> : null}
                    <WrappedComponent session={session} />
                </LayoutWrapper>
            </SessionContext.Provider>
        )
    }
}
